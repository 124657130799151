import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import './disabledoverlay.scss';

class DisabledOverlay extends Component {
    render() {
        return (
            <Fragment>
                {this.props.display &&
                <div className="disabled-overlay">
                    {this.props.children}
                </div>
                }
            </Fragment>
        );
    }
}

DisabledOverlay.propTypes = {
    display: PropTypes.bool
};

export default DisabledOverlay;