import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';


class AccessControl extends PureComponent {
    render() {
        return (
            <Fragment>
                {this.props.rightFn(this.props.login) &&
                this.props.children
                }

            </Fragment>
        );
    }
}

AccessControl.propTypes = {
    rightFn: PropTypes.func
};

function mapStateToProps(state) {
    return {
        login: state.login
    };
}


export default connect(mapStateToProps)(AccessControl);