import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class ConfirmDialog extends PureComponent {


    constructor(props, context) {
        super(props, context);
    }


    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.props.handleConfirm}>
                        {this.props.confirmButtonText}
                    </Button>
                    <Button variant="secondary" onClick={this.props.handleClose}>
                        Zavřít
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ConfirmDialog.propTypes = {
    title: PropTypes.string,
    show: PropTypes.bool,
    confirmButtonText: PropTypes.string,
    handleConfirm: PropTypes.func,
    handleClose: PropTypes.func

};

export default ConfirmDialog;