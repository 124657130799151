import React, {PureComponent} from 'react';
import HttpsRedirect from "react-https-redirect";
import App from "./App";


class HttpsApp extends PureComponent {

    render() {
        return (
            <HttpsRedirect>
                <App/>
            </HttpsRedirect>
        );
    }
}

export default HttpsApp;