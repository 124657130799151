import React, {Component} from 'react';
import FormikInput from "../input/FormikInput";
import {Formik} from "formik";

class RegistrationForm extends Component {

    render() {
        return (
            <Formik
                initialValues={this.props.initValue}
                onSubmit={this.props.onSubmit}
                validateOnChange={false}
                validateOnBlur={true}
                validate={this.props.validateForm}
                render={({
                             values,
                             errors,
                             status,
                             touched,
                             handleBlur,
                             handleChange,
                             handleSubmit,
                             isSubmitting,
                         }) => (

                    <form onSubmit={handleSubmit}>
                        {errors.message &&
                        <div className="text-danger small">
                            {errors.message}
                        </div>}
                        {this.props.visibleFields.firstName &&
                            <div className="form-group">
                                <label htmlFor="inputFirstName">Jméno</label>
                                <FormikInput type="text"
                                             className="form-control"
                                             id="inputFirstName"
                                             aria-describedby="firstNameHelp" placeholder="Zadejte vaše jméno"
                                             name="firstName"
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             value={values.firstName}
                                             invalid={!!errors.firstName}
                                />

                                {errors.firstName && touched.firstName &&
                                <div className="invalid-feedback">
                                    {errors.firstName}
                                </div>}
                            </div>
                        }
                        {this.props.visibleFields.surname &&
                            <div className="form-group">
                                <label htmlFor="inputSurname">Příjmení</label>
                                <FormikInput type="text" className="form-control" id="inputSurname"
                                             aria-describedby="surnameHelp" placeholder="Zadejte vaše příjmení"
                                             name="surname"
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             value={values.surname}
                                             invalid={!!errors.surname}
                                />

                                {errors.surname && touched.surname &&
                                <div className="invalid-feedback">
                                    {errors.surname}
                                </div>}
                            </div>
                        }
                        {this.props.visibleFields.email &&
                            <div className="form-group">
                                <label htmlFor="inputEmail">E-mail</label>
                                <FormikInput type="email" className="form-control" id="inputEmail"
                                             aria-describedby="emailHelp" placeholder="Zadejte váš e-mail"
                                             name="email"
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             value={values.email}
                                             invalid={!!errors.email}
                                />
                                {errors.email && touched.email &&
                                <div className="invalid-feedback">
                                    {errors.email}
                                </div>}
                                <small id="emailHelp" className="form-text text-muted">Nikdy nebudeme sdílet váš
                                    e-mail s nikým jiným.
                                </small>
                            </div>
                        }
                        {this.props.visibleFields.phone &&
                            <div className="form-group">
                                <label htmlFor="inputPhone">Telefon</label>
                                <FormikInput type="text" className="form-control" id="inputPhone"
                                             aria-describedby="phoneHelp" placeholder="např. 777123456"
                                             name="phone"
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             value={values.phone}
                                             invalid={!!errors.phone}/>
                                {errors.phone && touched.phone &&
                                <div className="invalid-feedback">
                                    {errors.phone}
                                </div>}

                                <small id="phoneHelp" className="form-text text-muted">Nikdy nebudeme sdílet
                                    vaše telefonní číslo s nikým jiným.
                                </small>
                            </div>
                        }
                        {this.props.visibleFields.password &&
                            <div className="form-group">
                                <label htmlFor="inputPass1">Heslo</label>
                                <FormikInput type="password" className="form-control" id="inputPass1"
                                             aria-describedby="passwordHelp"
                                             placeholder="Heslo"
                                             name="password1"
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             value={values.password1}
                                             invalid={!!errors.password1}/>

                                <small id="passwordHelp" className="form-text text-muted">Heslo musí obsahovat
                                    alespoň 8 znaků.
                                </small>
                                {errors.password1 && touched.password1 &&
                                <div className="invalid-feedback">
                                    {errors.password1}
                                </div>}
                            </div>
                        }
                        {this.props.visibleFields.password &&
                        <div className="form-group">
                            <label htmlFor="inputPass2">Potvrzení hesla</label>
                            <FormikInput type="password" className="form-control" id="inputPass2"
                                         placeholder="Potvrzení hesla"
                                         name="password2"
                                         onChange={handleChange}
                                         onBlur={handleBlur}
                                         value={values.password2}
                                         invalid={!!errors.password2}
                            />
                            {errors.password2 && touched.password2 &&
                            <div className="invalid-feedback">
                                {errors.password2}
                            </div>}
                        </div>
                        }
                        {this.props.visibleFields.personalInfoConsent &&
                        <div className="form-group form-check">
                            <FormikInput type="checkbox" className="form-check-input" id="personalInfo"
                                         name="personalInfoConsent"
                                         onChange={handleChange}
                                         onBlur={handleBlur}
                                         checked={values.personalInfoConsent}
                                         invalid={!!errors.personalInfoConsent}
                            />
                            <label htmlFor="personalInfo" className="form-check-labe small">
                                Souhlasím se zpracováním a uchováním výše uvedených údajů. Údaje Vámi
                                zadané nebudou poskytovány nikomu jinému a slouží pouze pro účely
                                správy rezervací.
                            </label>
                            {errors.personalInfoConsent && touched.personalInfoConsent &&
                            <div className="invalid-feedback">
                                {errors.personalInfoConsent}
                            </div>}
                        </div>
                        }

                        <button type="submit" className="btn btn-primary"
                                disabled={isSubmitting}>Registrovat
                        </button>
                    </form>
                )}
            />
        );
    }
}

export default RegistrationForm;