import React, {PureComponent} from 'react';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';
import {indexToTimeMax, indexToTimeMin} from "../../utils/dateutils";
import './bookedcourt.scss'
import {translateCourtCode} from "../../utils/courtUtils";


class BookedCourt extends PureComponent {


    render() {
        const time = indexToTimeMin(this.props.timeMin) + " - " + indexToTimeMax(this.props.timeMax);

        return (
            <div className="tile d-flex flex-row p-2 mb-2 bd-highlight border-bottom">
                {/*<div className="tile d-flex flex-row shadow p-2 mb-2 bd-highlight">*/}
                <div className={"d-flex flex-column flex-grow-1 " + (this.props.isCollision ? " text-red" : "")}>
                    <div>
                        <span
                        className="font-weight-bold">{time}</span></div>
                    <div><span
                        className={this.props.isCollision ? " text-red" : "text-black-50"}>{translateCourtCode(this.props.courtName)}</span>
                    </div>
                </div>
                <div>
                    <Button onClick={this.props.handleRemove} className="btn-light bg-white"><FontAwesomeIcon
                        icon="trash"/></Button>
                </div>
            </div>
        );
    }
}

BookedCourt.propTypes = {
    bookDay: PropTypes.string,
    courtName: PropTypes.string,
    timeMin: PropTypes.number,
    timeMax: PropTypes.number,
    handleRemove: PropTypes.func,
    isCollision: PropTypes.bool
};

export default BookedCourt;