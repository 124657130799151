import React, {PureComponent} from 'react';
import './app.scss';
import './icons.scss';
import {connect} from "react-redux"
import SchedulePage from "./pages/schedulepage/SchedulePage";
import ActivationPage from "./pages/activationpage/ActivationPage"
import {getLoggedUser} from "./actions";
import {faBackward, faCalendar, faForward, faTrash} from '@fortawesome/free-solid-svg-icons'
import {library} from "@fortawesome/fontawesome-svg-core";
import RegistrationPage from "./pages/registrationpage/RegistrationPage";
import {Route, Switch} from "react-router-dom";
import ChangePasswordPage from "./pages/changepasswordpage/ChangePasswordPage";
import UsersPage from "./pages/userspage/UsersPage";
import SequencePage from "./pages/sequencepage/SequencePage";
import UpdateRegistrationPage from "./pages/updateregistrationpage/UpdateRegistrationPage";

const whyDidYouRender = require('@welldone-software/why-did-you-render');
// whyDidYouRender(React, { include: [/.*/] });

// icons
library.add(faBackward, faForward, faCalendar, faTrash);

class App extends PureComponent {

    componentDidMount() {
        this.props.getLoggedUser();
    }

    render() {
        return (
            <div>
                <Switch>
                    <Route path="/activation/:activationCode" component={ActivationPage}/>
                    <Route path="/registration/update" component={UpdateRegistrationPage}/>
                    <Route path="/registration" component={RegistrationPage}/>
                    <Route path="/changePassword" component={ChangePasswordPage}/>
                    <Route path="/users/:userId" component={UsersPage}/>
                    <Route path="/users" component={UsersPage}/>
                    <Route path="/sequences" component={SequencePage}/>
                    <Route path="/loginError" component={SchedulePage}/>
                    <Route path="/" component={SchedulePage}/>
                </Switch>

                {/*<div>{process.env.REACT_APP_API_URL}</div>*/}
                {/*<div>{process.env.NODE_ENV}</div>*/}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        textToSee: state.value,
        login: state.login
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getLoggedUser: () => dispatch(getLoggedUser())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
