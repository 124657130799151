import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {getRegisteredUsers} from "../../actions";
import Page from "../Page";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import Table from "react-bootstrap/esm/Table";

class UsersPage extends Component {


    componentDidMount() {
        this.props.getRegisteredUsers();
    }


    render() {

        let selectedUserId = Number(this.props.match.params.userId);
        return (
            <Page>
                <div className="container mt-2 position-relative">
                    <LoadingSpinner loading={this.props.fetchingUsers}/>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Jméno</th>
                            <th>Příjmení</th>
                            <th>Email</th>
                            <th>Telefonní číslo</th>
                        </tr>
                        </thead>


                        <tbody>
                        {this.props.users
                            .map(user =>
                                <tr key={user.id} className={user.id === selectedUserId ? "table-info" : ""}>
                                    <td>{user.firstName}</td>
                                    <td>{user.surname}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phoneNumber}</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </Page>
        );
    }
}

UsersPage.propTypes = {};

function mapStateToProps(state) {
    return {
        fetchingUsers: state.actions.getRegisteredUsersLoading,
        users: state.registeredUsers
    }
}

export default connect(mapStateToProps, {
    getRegisteredUsers
})(UsersPage);
