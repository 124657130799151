import React, {PureComponent} from 'react';
import ConfirmDialog from "../confirmdialog/ConfirmDialog";
import connect from "react-redux/es/connect/connect";
import {removeBooking, hideRemoveBookingDialog} from "../../actions";
import AccessControl from "../accesscontrol/AccessControl";
import {admin} from "../../utils/rights";
import {indexToTimeMin} from "../../utils/dateutils";
import {translateCourtCode} from "../../utils/courtUtils";

class RemoveBookingDialog extends PureComponent {


    constructor(props, context) {
        super(props, context);

        this.deleteBooking = this.deleteBooking.bind(this);
        this.state = {
            notifyUserOnDelete: true
        }
    }

    deleteBooking(){
        this.props.removeBooking(
            this.props.courtCode,
            this.props.index,
            this.props.bookDay,
            this.state.notifyUserOnDelete
        );
    }


    render() {
        const username = this.props.username;
        const message =
            "Opravdu chcete odstranit rezervaci " +
            (admin(this.props.login) ? ("uživatele " + username) : "")
            + " v čase " + indexToTimeMin(this.props.index) + " na kurtu " + translateCourtCode(this.props.courtCode) + "?";



        return (
            <ConfirmDialog title={"Odstranit rezervaci"} confirmButtonText="Zrušit rezervaci"
                           show={this.props.show}
                           handleClose={this.props.hideRemoveBookingDialog}
                           handleConfirm={this.deleteBooking}>
                <div>

                    <div>
                        {message}
                    </div>

                    <AccessControl rightFn={admin}>
                        <div className="d-flex p-2">
                            <div><input type="checkbox" id="notifyUser" checked={this.state.notifyUserOnDelete}
                                        onChange={() => this.setState({
                                            ...this.state,
                                            notifyUserOnDelete: !this.state.notifyUserOnDelete
                                        })}

                            /></div>
                            <div className="ml-2"><label htmlFor="notifyUser">notifikovat uživatele emailem</label>
                            </div>
                        </div>
                    </AccessControl>
                </div>

            </ConfirmDialog>
        );
    }
}

RemoveBookingDialog.propTypes = {};

function mapStateToProps(state) {

    return {
        ...state.removeBookingDialog,
        login: state.login
    }
}

export default connect(mapStateToProps, {removeBooking, hideRemoveBookingDialog})(RemoveBookingDialog);