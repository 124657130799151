import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Calendar from "../calendar/Calendar";
import {parseDate, toLocalDate} from "../../utils/dateutils";
import {isMobileWidth} from "../../utils/utils";

class DateSelectionRow extends PureComponent {


    constructor(props, context) {
        super(props, context);
        this.selectPreviousDate = this.selectPreviousDate.bind(this);
        this.selectNextDate = this.selectNextDate.bind(this);
        this.selectToday = this.selectToday.bind(this);
    }

    skip = 1;

    selectPreviousDate() {
        let date = parseDate(this.props.bookDay);
        this.props.handleSelectDate(toLocalDate(new Date(date.getFullYear(), date.getMonth(), date.getDate() - this.skip)))
    }

    selectNextDate() {
        let date = parseDate(this.props.bookDay);
        this.props.handleSelectDate(toLocalDate(new Date(date.getFullYear(), date.getMonth(), date.getDate() + this.skip)))
    }

    selectToday() {
        this.props.handleSelectDate(toLocalDate(new Date()));
    }


    render() {
        return (
            <Fragment>
                {!isMobileWidth() &&
                <div className="m-1">
                    <Button variant="outline-secondary" onClick={this.selectPreviousDate}><FontAwesomeIcon icon="backward"/></Button>
                </div>
                }
                <div className="m-1">
                    <Button variant="outline-secondary" onClick={this.selectToday}>Dnes</Button>
                </div>

                {!isMobileWidth() &&
                <div className="m-1">
                    <Button variant="outline-secondary" onClick={this.selectNextDate}><FontAwesomeIcon icon="forward"/></Button>
                </div>
                }
                <div className="m-1 text-right flex-grow-1">
                    <Calendar selected={this.props.bookDay}
                              handleSelect={(date) => this.props.handleSelectDate(toLocalDate(date))}/>
                </div>
            </Fragment>

        );
    }
}

DateSelectionRow.propTypes = {
    bookDay: PropTypes.string,

    handleSelectDate: PropTypes.func
};

export default DateSelectionRow;