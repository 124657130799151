import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import Page from "../Page";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import Table from "react-bootstrap/esm/Table";
import {getSequences, LoadingEnum} from "../../actions";
import {LongTermPeriod} from "../../utils/enums";
import {indexToTimeMax, indexToTimeMin, parseDate} from "../../utils/dateutils";
import {translateCourtCode} from "../../utils/courtUtils";


class SequencePage extends Component {

    longTerms = [];
    dayNames = [
        "v pondělí",
        "v úterý",
        "ve středu",
        "ve čtvrtek",
        "v pátek",
        "v sobotu",
        "v neděli"
    ]

    constructor(props, context) {
        super(props, context);
        Object.keys(LongTermPeriod).forEach(key =>
            this.longTerms[LongTermPeriod[key].key] = LongTermPeriod[key].title
        );
    }

    componentDidMount() {
        this.props.getSequences();
    }


    render() {


        return (
            <Page>
                <div className="container mt-2 position-relative">
                    <LoadingSpinner loading={this.props.fetchingUsers}/>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Zadavatel</th>
                            <th>Frekvence</th>
                            <th>Od</th>
                            <th>Do</th>
                            <th>Začátek platnosti</th>
                            <th>Konec platnosti</th>
                            <th>Popis</th>
                            <th>Kurt</th>
                        </tr>
                        </thead>


                        <tbody>
                        {this.props.sequences
                            .map(seq => {
                                    const dayInWeek = parseDate(seq.startDate).getDay() - 1;

                                    return (
                                        <tr key={seq.id}>
                                            <td>{seq.user.name}</td>
                                            <td>{
                                                this.longTerms[seq.period] + " " + this.dayNames[dayInWeek]
                                            }</td>
                                            <td>{indexToTimeMin(seq.from)}</td>
                                            <td>{indexToTimeMax(seq.to)}</td>
                                            <td>{seq.startDate}</td>
                                            <td>{seq.endDate}</td>
                                            <td>{seq.description}</td>
                                            <td>{translateCourtCode(seq.court)}</td>
                                        </tr>);
                                }
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </Page>
        );
    }
}

SequencePage.propTypes = {};

function mapStateToProps(state) {
    return {
        fetchingSequences: state.actions[LoadingEnum.SEQUENCE_LIST],
        sequences: state.sequences
    }
}

export default connect(mapStateToProps, {
    getSequences
})(SequencePage);
