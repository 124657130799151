import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Spinner from "react-bootstrap/esm/Spinner";
import './loadingspinner.scss';

class LoadingSpinner extends Component {

    render() {
        return (
            <Fragment>
                {this.props.loading &&
                <SpinnerInner/>
                }
            </Fragment>
        );
    }
}

class SpinnerInner extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            display: false,
        };
        this.display = this.display.bind(this);
        this.timer = setTimeout(this.display, 250);
    }

    display() {
        this.setState({display: true})
    }


    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    render() {
        return (
            <Fragment>
                {this.state.display &&
                <div className="spinner-overlay">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
                }
            </Fragment>
        );
    }
}


LoadingSpinner.propTypes = {
    loading: PropTypes.bool
};

export default LoadingSpinner;