import React, {Fragment, PureComponent} from 'react';
import './navigation.scss';
import LoginInfo from "../login/LoginInfo";
import connect from "react-redux/es/connect/connect";
import {getCourtSchedule, hideLoginDialog, logout, showLoginDialog} from "../../actions";
import LoginDialog from "../login/LoginDialog";
import Navbar from "react-bootstrap/esm/Navbar";
import Nav from "react-bootstrap/esm/Nav";
import AccessControl from "../accesscontrol/AccessControl";
import {admin} from "../../utils/rights";
import {Link} from "react-router-dom";
import {toLocalDate} from "../../utils/dateutils";
import {LinkContainer} from "react-router-bootstrap";
import NavLink from "react-bootstrap/esm/NavLink";


class Navigation extends PureComponent {


    constructor(props, context) {
        super(props, context);
        this.selectToday = this.selectToday.bind(this);
    }

    selectToday() {
        this.props.getCourtSchedule(toLocalDate(new Date()));
    }

    render() {

        const {...rest} = this.props;
        return (
            <Fragment>
                <LoginDialog show={this.props.showDialog} handleClose={this.props.hideLoginDialog}/>

                <Navbar bg="light" expand="sm">
                    <Navbar.Brand><Link to="/" className="navbar-brand" >Tenis Dolní Dobrouč</Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <AccessControl rightFn={admin}>
                                <LinkContainer exact to="/">
                                    <NavLink>Rezervace</NavLink>
                                </LinkContainer>
                                <LinkContainer to="/users">
                                    <NavLink>Uživatelé</NavLink>
                                </LinkContainer>
                                <LinkContainer to="/sequences">
                                    <NavLink>Řady</NavLink>
                                </LinkContainer>
                            </AccessControl>
                        </Nav>
                        <Nav>
                        <LoginInfo {...rest} login={this.props.login} handleLogin={this.props.showLoginDialog}
                                   handleLogout={this.props.logout}/>
                        </Nav>

                    </Navbar.Collapse>
                </Navbar>
            </Fragment>

        );
    }


}


function mapStateToProps(state) {

    return {
        login: state.login,
        showDialog: state.login.showDialog
    }
}

export default connect(mapStateToProps, {getCourtSchedule, logout, showLoginDialog, hideLoginDialog})(Navigation);