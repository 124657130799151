import React, {PureComponent} from 'react';
import './timepicker.scss'
import connect from "react-redux/es/connect/connect";
import {TimePickerRow} from "./TimePickerRow";
import {bookingType} from "../../utils/enums";
import {evaluateDeletableIndex} from "../../utils/bookutils";
import {selectedTimeUpdated} from "../../actions";

class TimePicker extends PureComponent {

    ChunkType = Object.freeze({
        SINGLE: {border: ""},
        TOP: {border: "border-bottom-none"},
        INNER: {border: "border-bottom-none border-top-none"},
        BOTTOM: {border: "border-top-none"},
        NONE: {border: ""}
    });

    constructor(props, context) {
        super(props);

        this.mouseClick = this.mouseClick.bind(this);
        this.chunkInfo = this.chunkInfo.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.handleBookingDelete = this.handleBookingDelete.bind(this);
    }


    schedulableTimes() {
        let hour = 7;
        let minutes = ["00", "30"];

        let endHour = 21;

        let times = [];
        while (hour <= endHour) {
            times.push(hour + ":" + minutes[0]);
            times.push(hour + ":" + minutes[1]);
            hour++;
        }

        return times;
    }

    mouseClick(id) {
        let chunkInfo = this.chunkInfo(id);
        if (chunkInfo.type !== this.ChunkType.NONE) {
            return;
        }

        if(chunkInfo.expired){
            return;
        }


        let selectedIds = [...this.props.selectedIds];
        //set selected item -> clear selection
        if (selectedIds.includes(id)) {
            selectedIds = this.props.selectedIds.filter(selectedId => selectedId !== id);
        } else {
            selectedIds.push(id);
        }

        this.handleSelection(selectedIds);
    }


    chunkInfo(id) {
        let expired = id <= this.props.expiredIndex;

        if (!this.props.fullIds) {
            return {
                type: this.ChunkType.NONE,
                expired: expired
            };
        }

        for (let i = 0; i < this.props.fullIds.length; i++) {

            let chunk = this.props.fullIds[i];
            if (chunk.bookedTimeIds.includes(id)) {
                const expiredParent = chunk.bookedTimeIds[0] <= this.props.expiredIndex;
                if (chunk.bookedTimeIds[0] === id) {
                    if (chunk.bookedTimeIds.length === 1) {
                        return this.toChunkInfo(this.ChunkType.SINGLE, chunk, expiredParent);
                    }
                    if (chunk.bookedTimeIds.length > 1) {
                        return this.toChunkInfo(this.ChunkType.TOP, chunk, expiredParent);
                    }
                }

                if (chunk.bookedTimeIds[chunk.bookedTimeIds.length - 1] === id) {
                    return this.toChunkInfo(this.ChunkType.BOTTOM, chunk, expiredParent);
                }

                return this.toChunkInfo(this.ChunkType.INNER, chunk, expiredParent);
            }
        }

        return {
            type: this.ChunkType.NONE,
            expired: expired
        };
    }

    toChunkInfo(chunkType, chunk, expired){
        return {
            type: chunkType,
            user: chunk.user,
            bookingType: chunk.bookingType,
            longTerm: chunk.longTerm,
            description: chunk.description,
            expired: expired,
        };
    }


    handleSelection(selectedIndex) {
        this.props.selectedTimeUpdated(
            {
                code: this.props.courtCode,
                selectedIds: [...selectedIndex]
            }, this.props.bookDay);
    }

    evaluateChunkClassNames(chunkInfo, selected){
        let result = "";

        if(selected){
            result = "selected";
        } else if (chunkInfo.expired) {
            if(chunkInfo.bookingType === bookingType.training){
                result = "full-training expired";
            } else if (chunkInfo.type != this.ChunkType.NONE) {
                result = "full expired";
            } else {
                result = "expired";
            }

        } else if (chunkInfo.bookingType === bookingType.simple) {
            result = "full";
        } else if (chunkInfo.bookingType === bookingType.training) {
            result = "full-training";
        } else if (chunkInfo.type != this.ChunkType.NONE) {
            result = "full";
        }

        return result + " " + chunkInfo.type.border;
    }

    handleBookingDelete(user, index) {
        const username = user.firstName + " " + user.surname;
        this.props.handleBookingDelete(username, index, this.props.bookDay, this.props.courtCode);
    }

    render() {
        let times = this.schedulableTimes();
        const deletableIndex = evaluateDeletableIndex(this.props.bookDay);
        return (
            <div>
                <table className="time-picker table table-bordered">
                    <tbody>
                    {
                        times.map((time, index) => {
                            const chunkInfo = this.chunkInfo(index);
                            const classNames = this.evaluateChunkClassNames(chunkInfo, this.props.selectedIds.includes(index));

                            return <TimePickerRow
                                key={index}
                                index={index}
                                classNames={classNames}
                                onClick={this.mouseClick}
                                time={time}
                                expired={chunkInfo.expired}
                                login={this.props.login}
                                deletableIndex={deletableIndex}
                                fullChunkUser={chunkInfo.user}
                                chunkTop={chunkInfo.type === this.ChunkType.TOP
                                || chunkInfo.type === this.ChunkType.SINGLE}
                                bookingType={chunkInfo.bookingType}
                                onRemoveBooking={this.handleBookingDelete}
                                description={chunkInfo.description}
                                longTerm={chunkInfo.longTerm}
                            />
                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    let selectedTime = state.booking.selectedTimes.filter(selectedTime => selectedTime.day === state.bookDay);
    let selectedIds = [];

    if (selectedTime.length > 0) {
        let court = selectedTime[0].courts.filter(court => court.code === props.courtCode);
        if (court.length > 0) {
            selectedIds = court[0].selectedIds;
        }
    }

    return {
        loading: state.loading,
        bookDay: state.bookDay,
        selectedIds: selectedIds,
        login: state.login
    }
}

export default connect(mapStateToProps, {selectedTimeUpdated})(TimePicker);