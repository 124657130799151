import React, {PureComponent} from 'react';
import Page from "../Page";
import {activateUser, startActivation} from "../../actions";
import {connect} from "react-redux";
import {ActivationStatus} from "../../utils/enums";
import {Link} from "react-router-dom";


class ActivationPage extends PureComponent {

    componentDidMount() {
        this.props.startActivation();
        this.props.activateUser(this.props.match.params.activationCode);
    }


    render() {

        return <Page>
            <div className="container w-50">
                <div className="row justify-content-center mt-4">
                    {this.props.activationStatus === ActivationStatus.STARTED &&
                    <h2>Aktivuji účet...</h2>
                    }

                    {this.props.activationStatus === ActivationStatus.SUCCESS &&
                    <div>
                        <h2>Váš účet byl úspěšně aktivován.</h2>
                        <div>Nyní můžete zakládat rezervace.</div>

                        <Link className="btn btn-primary mt-4" to="/">
                            Pokračovat na rezervace
                        </Link>
                    </div>
                    }

                    {this.props.activationStatus === ActivationStatus.FAILED &&
                    <div>
                        <h2>Aktivace se nezdařila</h2>
                        <div>Kontaktujte správce kurtů</div>

                    </div>
                    }

                </div>
            </div>

        </Page>

    }

}

const mapDispatchToProps = dispatch => {
    return {
        startActivation: () => dispatch(startActivation()),
        activateUser: (activationCode) => dispatch(activateUser(activationCode))
    }
};

function mapStateToProps(state) {

    return {
        activationStatus: state.activationStatus
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ActivationPage);
