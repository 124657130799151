import {hashBooking} from "../utils/bookutils";
import {ActivationStatus} from "../utils/enums";

export const LoadingEnum = {
    SEQUENCE_LIST: "sequencesLoading",
    USERS_LIST: "getRegisteredUsersLoading"
}


export const LOADING = "LOADING";
export const SCHEDULE_LOADED = "SCHEDULE_LOADED";
export const SCHEDULE_LOADING = "SCHEDULE_LOADING";
export const GET_REGISTERED_USERS_LOADING = "GET_REGISTERED_USERS_LOADING";
export const GET_REGISTERED_USERS_LOADED = "GET_REGISTERED_USERS_LOADED";
export const GET_SEQUENCES_LOADED = "GET_SEQUENCES_LOADED";
export const BOOK_COURT = "BOOK_COURT";
export const BOOK_COURT_FAILED = "BOOK_COURT_FAILED";
export const CREATE_SEQUENCE_FAILED = "CREATE_SEQUENCE_FAILED";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";
export const USER_LOGOUT = "USER_LOGOUT";
export const SHOW_LOGIN_DIALOG = "SHOW_LOGIN_DIALOG";
export const HIDE_LOGIN_DIALOG = "HIDE_LOGIN_DIALOG";
export const SELECTED_TIMES_UPDATED = "SELECTED_TIMES_UPDATED";
export const RESET_SELECTED_TIMES = "RESET_SELECTED_TIMES";
export const ACTIVATION_START = "ACTIVATION_START";
export const ACTIVATION_FINISHED = "ACTIVATION_FINISHED";
export const SHOW_REMOVE_BOOKING_DIALOG = "SHOW_REMOVE_BOOKING_DIALOG";
export const HIDE_REMOVE_BOOKING_DIALOG = "HIDE_REMOVE_BOOKING_DIALOG";
export const REMOVE_BOOKING_FINISHED = "REMOVE_BOOKING_FINISHED";
export const HANDLE_401_STATUS = "HANDLE_401_STATUS";


export const actionLoading = action => ({type: LOADING, action: action});
export const actionScheduleLoading = day => ({type: SCHEDULE_LOADING, bookDay: day});
export const actionScheduleLoaded = payload => ({type: SCHEDULE_LOADED, payload: payload});
export const actionGetRegisteredUsersLoading = () => ({type: GET_REGISTERED_USERS_LOADING});
export const actionGetRegisteredUsersLoaded = (users) => ({type: GET_REGISTERED_USERS_LOADED, users: users});
export const actionGetSequencesLoaded = (sequences) => ({type: GET_SEQUENCES_LOADED, sequences: sequences});
export const actionBookCourt = payload => ({type: BOOK_COURT, payload: payload});
export const bookCourtFailed = (collisions, globalCollisions) => ({type: BOOK_COURT_FAILED, collisionHashArray: collisions, globalCollisions: globalCollisions});
export const createSequenceFailed = collisions => ({type: CREATE_SEQUENCE_FAILED, sequenceCollisions: collisions});
export const userLoggedIn = user => ({type: USER_LOGGED_IN, user: user});
export const userLogout = () => ({type: USER_LOGOUT});
export const userLoginFailed = (errorMessage) => ({type: USER_LOGIN_FAILED, errorMessage: errorMessage});
export const showLoginDialog = () => ({type: SHOW_LOGIN_DIALOG});
export const hideLoginDialog = () => ({type: HIDE_LOGIN_DIALOG});
export const startActivation = () => ({type: ACTIVATION_START});
export const finishedActivation = (status) => ({type: ACTIVATION_FINISHED, activationStatus: status});
export const showRemoveBookingDialog = (username, index, bookDay, courtCode) => ({
    type: SHOW_REMOVE_BOOKING_DIALOG,
    username: username,
    index: index,
    courtCode: courtCode,
    bookDay: bookDay
});
export const hideRemoveBookingDialog = () => ({type: HIDE_REMOVE_BOOKING_DIALOG});
export const removeBookingFinished = (indexFrom, bookDay) => ({
    type: REMOVE_BOOKING_FINISHED,
    indexFrom: indexFrom,
    bookDay: bookDay
});
export const handle401Status = () => ({type: HANDLE_401_STATUS});

export function getCourtSchedule(day) {
    return dispatch => {

        dispatch(actionScheduleLoading(day));

        return fetch(toServerUrl(`/courts?date=${day}`), params(null))
            .then(response => response.json())
            .then(json => {
                window.location.hash = `#/?date=${day}`;
                dispatch(actionScheduleLoaded(json));
            });
    };
}

export function getRegisteredUsers() {
    return dispatch => {
        dispatch(actionGetRegisteredUsersLoading());

        return fetch(toServerUrl("/users"), params(null))
            .then(checkLoginRequired)
            .then(resp => resp.json())
            .then(json => dispatch(actionGetRegisteredUsersLoaded(json.values)))
            .catch(e => {
                dispatch(handleError(e));
            })
    }
}

export function selectedTimeUpdated(data, day) {
    return {
        type: SELECTED_TIMES_UPDATED,
        bookDay: day,
        courtCode: data.code,
        selectedIds: data.selectedIds,
    }
}

export const resetSelectedTime = () => ({
    type: RESET_SELECTED_TIMES
});


export function bookCourt(details) {

    return (dispatch, getState) => {
        dispatch(actionBookCourt(getState().booking.selectedTimes));
        fetch(toServerUrl("/courts"), params({
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...getState().booking, ...details})
        }))
            .then(checkLoginRequired)
            .then((res) => {
                if (res.ok) {
                    dispatch(resetSelectedTime());
                    dispatch(getCourtSchedule(getState().bookDay));
                } else {
                    if (res.status === 400) {
                        res.json()
                            .then((json) => {
                                if (json.code === 'BOOKING_COLLISION') {
                                    dispatch(
                                        bookCourtFailed(
                                            json.collisions
                                                .filter(col => col.id !== null)
                                                .map(col => hashBooking(col.courtType, col.date, col.id)),
                                            json.collisions
                                                .filter(col => col.id === null)
                                                .map(col => col)
                                        ));
                                }
                            })
                    }
                }

            })
            .catch(e => {
                dispatch(bookCourtFailed([]));
                dispatch(handleError(e));
            })

    };
}


export function removeBooking(courtType, startId, bookDay, notifyUserOnDelete) {
    return (dispatch, getState) => {
        fetch(toServerUrl("/courts/remove-booking"), params({
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                courtCode: courtType,
                startId: startId,
                day: bookDay,
                notifyUser: notifyUserOnDelete
            })
        }))
            .then(checkLoginRequired)
            .then((res) => {
                    if (res.ok) {
                        dispatch(removeBookingFinished(startId, bookDay));
                        if (getState().bookDay === bookDay) {
                            dispatch(getCourtSchedule(bookDay))
                        }
                    }
                }
            )
            .catch(e => {
                dispatch(handleError(e));
            })
    }
}

export function bookSequence(details) {

    return (dispatch, getState) => {
        dispatch(actionBookCourt(getState().booking.selectedTimes));
        fetch(toServerUrl("/sequences"), params({
            method: 'post',
            body: JSON.stringify({
                longTermFirstDay: getState().booking.selectedTimes[0].day,
                selectedTime: getState().booking.selectedTimes[0].courts[0],
                ...details
            })
        }))
            .then(checkLoginRequired)
            .then((res) => {
                if (res.ok) {
                    dispatch(resetSelectedTime());
                    dispatch(getCourtSchedule(getState().bookDay));
                } else {
                    if (res.status === 400) {
                        res.json()
                            .then((json) => {
                                if (json.code === 'LONG_TERM_BOOKING_COLLISION') {
                                    dispatch(createSequenceFailed(json.collisions));
                                }
                            })
                    }
                }

            })
            .catch(e => {
                dispatch(bookCourtFailed([]))
                dispatch(handleError(e));
            })

    };
}

export function getSequences() {
    return dispatch => {
        dispatch(actionLoading(LoadingEnum.SEQUENCE_LIST));

        return fetch(toServerUrl("/sequences"), params(null))
            .then(checkLoginRequired)
            .then(resp => resp.json())
            .then(json => dispatch(actionGetSequencesLoaded(json.values)))
            .catch(e => {
                dispatch(handleError(e));
            })
    }
}


export function registerUser(userData, form, onSuccess) {
    return () => {
        fetch(toServerUrl("/users/register"), params({
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData)

        }))

            .then((res) => {
                form.setSubmitting(false);
                if (res.ok) {
                    onSuccess();
                } else {
                    if (res.status === 400) {
                        res.json().then((json) => {
                            let array = {message: json.message};
                            json.errorFields.map(item => {
                                array[item.name] = item.message;
                            });
                            form.setErrors(array);
                        })
                    }
                }
            })
    }
}

export function changePassword(passwordData, form, onSuccess) {
    return (dispatch) => {
        fetch(toServerUrl("/users/changePassword"), params({
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(passwordData)
        }))
            .then(checkLoginRequired)
            .then((res) => {
                if (res.ok) {
                    onSuccess();
                } else {
                    if (res.status === 400) {
                        res.json().then((json) => {
                            let array = {message: json.message};
                            json.errorFields.map(item => {
                                array[item.name] = item.message;
                            });
                            form.setErrors(array);
                        })
                    }
                }
            })
            .catch(e => {
                dispatch(handleError(e));
            })
            .finally(() =>
                form.setSubmitting(false)
            )
    }
}

export function login(username, password) {
    return (dispatch) => {
        fetch(toServerUrl("/dologin"), params({
            method: 'post',
            headers: {
                'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({username: username, password: password})
        }))
            .then(v => {
                if (v.status === 200) {
                    dispatch(getLoggedUser())
                } else {
                    let errorMessage;
                    switch (v.status) {
                        case 401:
                            errorMessage = "Chybné uživatelské jméno nebo heslo";
                            break;
                        case 402:
                            errorMessage = "Váš účet není aktivní. Ověřte potvrzení odkazu v aktivačním emailu, který vám přišel po registraci na Vámi zadanou emailovou adresu.";
                            break;
                        default:
                            errorMessage = "Chyba při přihlášení.";
                    }

                    dispatch(userLoginFailed(errorMessage))
                }
            })
            .catch(e => {
                console.error("Error during login: ", e)
            })
    }
}

export function loginGoogle() {
    fetch(toServerUrl("/oauth2/authorization/google"), params({
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Origin': 'http://localhost:3000'
        }
    }));
}

export function logout() {
    return (dispatch) => {
        fetch(toServerUrl("/dologout"), params())
            .then(res => {
                dispatch(userLogout());
            })
    }
}

export function getLoggedUser() {
    return (dispatch) => {

        fetch(toServerUrl("/users/logged"), params())
            .then(res => {
                    if (res.ok) {
                        res.json()
                            .then((json) => dispatch(userLoggedIn(json)));
                    }
                }
            );

    }
}

export function activateUser(activationCode) {
    return (dispatch) => {
        fetch(toServerUrl("/users/activate/" + activationCode), params({
            method: 'post'
        }))
            .then(res => {
                dispatch(finishedActivation(res.ok ? ActivationStatus.SUCCESS : ActivationStatus.FAILED));
            })
    }
}

function checkLoginRequired(resp) {
    if (resp.status === 401) {
        throw new FetchError(resp.status);
    } else {
        return resp;
    }
}

function handleError(error) {
    if (error.status === 401) {
        return handle401Status();
    } else {
        //TODO kubovy handle others
        throw new Error("Not handled error", error);
    }
}

var toServerUrl = (url) => {
    return process.env.REACT_APP_API_URL + url;
}

var params = (params) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        ...params,
        credentials: "include"
    }
}

export class FetchError {
    constructor(status) {
        this.status = status;
    }
}