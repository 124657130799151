import React, {PureComponent} from 'react';
import Modal from "react-bootstrap/Modal";
import LoginForm from "./LoginForm";
import connect from "react-redux/es/connect/connect";
import {hideLoginDialog, login} from "../../actions";
import GoogleButton from "react-google-button";
import FormGroup from "react-bootstrap/esm/FormGroup";
import {inDevelopment} from "../../index";

class LoginDialog extends PureComponent {


    constructor(props, context) {
        super(props, context);
        this.handleLogin = this.handleLogin.bind(this);

    }

    handleLogin(username, password) {
        this.props.login(username, password);
    }


    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Přihlášení</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {inDevelopment() &&
                    //    TODO kubovy GOOGLE OAUTH2
                    <FormGroup>
                            <span className="font-weight-bold">
                             Přihlášení pomocí sociálního účtu
                            </span>
                            <a className="ml-auto mr-auto d-inline-block w-auto" href="http://localhost:8080/oauth2/authorization/google">
                                <GoogleButton className="mt-3" type={"light"} label="Google účet"/>
                            </a>
                        </FormGroup>
                    }
                    <LoginForm id={"loginForm"}
                               loginError={this.props.loginError}
                               errorMessage={this.props.errorMessage}
                               handleClose={this.props.handleClose}
                               handleSubmit={this.handleLogin}/>

                </Modal.Body>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        errorMessage: state.login.errorMessage,
        loginError: state.login.loginError
    };
}

export default connect(mapStateToProps, {hideLoginDialog, login})(LoginDialog);