import {isDateEqual, parseDate, timeToIndex} from "./dateutils";
import {DELETABLE_BEFORE_START_MINUTES, SELECTABLE_CHUNKS_AFTER_MINUTES} from "../config/config";

export function createBookChunks(selectedTimes) {
    const result = [];


    selectedTimes.forEach(day => {
        day.courts.forEach(court => {
            const sortedIds = court.selectedIds.sort((a, b) => a - b);
            if (sortedIds.length === 0) {
                return;
            }

            let min = null;
            let max = min;

            sortedIds.forEach(id => {
                if (min == null) {
                    min = id;
                    max = id;
                    return;
                }

                if (id === max || id === max + 1) {
                    max = id;
                    return;
                }

                result.push(
                    createChunk(min, max, court.code, day.day));
                min = id;
                max = id;
            })

            if (min != null) {
                result.push(
                    createChunk(min, max, court.code, day.day));
            }
        })
    })

    return result.sort((a, b) => {
        let result = parseDate(a.day) - parseDate(b.day);
        if (result === 0) {
            result = a.minId - b.minId;
        }
        return result;
    })
}

export function isBookedChunk(selectedTimes) {
    return createBookChunks(selectedTimes).length > 0;
}


export function hashBooking(courtCode, dayString, index) {
    return courtCode + dayString + "_" + index;
}


export function evaluateExpiredIndex(bookDayString){
    let bookDay = parseDate(bookDayString);
    let today = new Date();


    if (isDateEqual(bookDay, today)) {
        let index = timeToIndex(today);
        index += SELECTABLE_CHUNKS_AFTER_MINUTES / 30;
        return index;
    } else if (bookDay < today) {
        return 100;
    } else {
        return -1;
    }
}

export function evaluateDeletableIndex(bookDayString){
    let bookDay = parseDate(bookDayString);
    let today = new Date();


    if (isDateEqual(bookDay, today)) {
        let index = timeToIndex(today);
        index += DELETABLE_BEFORE_START_MINUTES / 30;
        return index;
    } else if (bookDay < today) {
        return 100;
    } else {
        return -1;
    }
}


function createChunk(minId, maxId, courtCode, day) {
    return {
        minId: minId,
        maxId: maxId,
        courtCode: courtCode,
        day: day
    }
}