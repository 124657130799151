import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class FormikInput extends PureComponent {
    render() {
        let {className, invalid, ...rest} = this.props;

        if (invalid) {
            className = [className, "is-invalid"].join(" ");
        }

        return (
            <input
                {...rest}
                className={className}
            />
        );
    }
}

FormikInput.propTypes = {
    invalid: PropTypes.bool
};

export default FormikInput;