import React, {PureComponent} from 'react';
import Page from "../Page";
import {Formik} from "formik";
import FormikInput from "../../components/input/FormikInput";
import connect from "react-redux/es/connect/connect";
import {changePassword} from "../../actions";
import {Link} from "react-router-dom";
import {admin, userLoggedIn} from "../../utils/rights";

class ChangePasswordPage extends PureComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {passwordChanged: false};
    }

    validateForm(values) {
        let errors = {};
        const {oldPassword, password1, password2} = values;

        if (!oldPassword) {
            errors.oldPassword = "Není vyplněné současné heslo";
        }

        if (!password1 || password1.length < 8) {
            errors.password1 = "Heslo musí obsahovat alespoň 8 znaků";
        }

        if (!password2 || password2 !== password1) {
            errors.password2 = "Zadaná hesla se neschodují";
        }

        return errors;
    };


    render() {
        return (
            <Page rightFn={userLoggedIn}>
                <div className="container w-50">
                    <div className="row justify-content-center mt-4">
                        <h2>Změna hesla</h2>
                    </div>

                    {this.state.passwordChanged &&

                    <div className="row flex-column align-items-center mt-4 text-success bold h4">
                        <div>
                            Vaše heslo bylo změněno
                        </div>
                        <div>
                            <Link className="btn btn-primary mt-4" to="/">
                                Pokračovat na rezervace
                            </Link>
                        </div>
                    </div>
                    }

                    {!this.state.passwordChanged &&
                    <div className="row justify-content-md-center mt-4">
                        <Formik
                            onSubmit={(values, actions) => {
                                const {setSubmitting, setErrors} = actions;
                                this.props.changePassword(values, {
                                    setSubmitting,
                                    setErrors
                                }, () => this.setState({passwordChanged: true})); //TODO kubovy console odebrat
                            }}
                            validateOnChange={false}
                            validateOnBlur={true}
                            validate={this.validateForm}
                            render={({
                                         values,
                                         errors,
                                         status,
                                         touched,
                                         handleBlur,
                                         handleChange,
                                         handleSubmit,
                                         isSubmitting,
                                     }) => (

                                <form onSubmit={handleSubmit}>
                                    {errors.message &&
                                    <div className="text-danger small">
                                        {errors.message}
                                    </div>}
                                    <div className="form-group">
                                        <label htmlFor="oldPass">Heslo</label>
                                        <FormikInput type="password" className="form-control" id="oldPass"
                                                     placeholder="Staré heslo"
                                                     name="oldPassword"
                                                     onBlur={handleBlur}
                                                     value={values.oldPassword}
                                                     onChange={handleChange}
                                                     invalid={errors.oldPassword}/>
                                        {/*<ErrorMessage name="oldPassword"/>*/}

                                        {errors.oldPassword && touched.oldPassword &&
                                        <div className="invalid-feedback">
                                            {errors.oldPassword}
                                        </div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="inputPass1">Heslo</label>
                                        <FormikInput type="password" className="form-control" id="inputPass1"
                                                     aria-describedby="passwordHelp"
                                                     placeholder="Nové heslo"
                                                     name="password1"
                                                     onChange={handleChange}
                                                     onBlur={handleBlur}
                                                     value={values.password1}
                                                     invalid={errors.password1}/>

                                        <small id="passwordHelp" className="form-text text-muted">Heslo musí obsahovat
                                            alespoň 8 znaků.
                                        </small>
                                        {errors.password1 && touched.password1 &&
                                        <div className="invalid-feedback">
                                            {errors.password1}
                                        </div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="inputPass2">Potvrzení hesla</label>
                                        <FormikInput type="password" className="form-control" id="inputPass2"
                                                     placeholder="Potvrzení nového hesla"
                                                     name="password2"
                                                     onChange={handleChange}
                                                     onBlur={handleBlur}
                                                     value={values.password2}
                                                     invalid={errors.password2}
                                        />
                                        {errors.password2 && touched.password2 &&
                                        <div className="invalid-feedback">
                                            {errors.password2}
                                        </div>}
                                    </div>

                                    <button type="submit" className="btn btn-primary"
                                            disabled={isSubmitting}>Změnit heslo
                                    </button>
                                </form>
                            )}
                        />
                    </div>
                    }
                </div>
            </Page>
        );
    }
}

ChangePasswordPage.propTypes = {};

export default connect(
    null, {changePassword}
)(ChangePasswordPage);