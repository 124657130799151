import {
    ACTIVATION_FINISHED,
    ACTIVATION_START,
    BOOK_COURT,
    BOOK_COURT_FAILED,
    CREATE_SEQUENCE_FAILED,
    GET_REGISTERED_USERS_LOADED,
    GET_REGISTERED_USERS_LOADING,
    GET_SEQUENCES_LOADED,
    HANDLE_401_STATUS,
    HIDE_LOGIN_DIALOG,
    HIDE_REMOVE_BOOKING_DIALOG,
    LOADING,
    REMOVE_BOOKING_FINISHED,
    RESET_SELECTED_TIMES,
    SCHEDULE_LOADED,
    SCHEDULE_LOADING,
    SELECTED_TIMES_UPDATED,
    SHOW_LOGIN_DIALOG,
    SHOW_REMOVE_BOOKING_DIALOG,
    USER_LOGGED_IN,
    USER_LOGIN_FAILED,
    USER_LOGOUT
} from "../../actions";
import {ActivationStatus as ActivationStatus, LongTermPeriod} from "../../utils/enums";

const initialState = {
        actions: {
            bookingInProgress: false,
            getRegisteredUsersLoading: false
        },
        login: {
            username: "",
            roles: [],
            userLoggedIn: false,
            loginError: false,
            showDialog: false
        },
        bookDay: null,
        loading: false,
        booking: {
            selectedTimes: [
                // {
                //     day: null,
                //     courts: [
                //         {
                //             code: "KURT_1",
                //             selectedIds: []
                //         },
                //         {
                //             code: "KURT_2",
                //             selectedIds: []
                //         },
                //         {
                //             code: "KURT_3",
                //             selectedIds: []
                //         }
                //     ]
                // }
            ]
        },
    selectedTimesCollisions: [
        //"KURT_12019..."
    ],
    globalCollisions: [],
    sequences: [],
    sequenceCollisions: [],
    courts: {
        // KURT_1: [
        //     {
        //         userId: 4,
        //         bookedTimeIds: [3, 4, 5, 6, 7, 8]
        //     }]
    },
    removeBookingDialog: {show: false},
        registeredUsers: []
    }
;


function rootReducer(state = initialState, action) {

    switch (action.type) {
        case LOADING: {
            const result = {...state};
            result.actions = {...state.actions};
            result.actions[action.action] = true;
            return result;
        }

        case BOOK_COURT:
            return {...state, selectedTimesCollisions: [], globalCollisions: [], actions: {...state.actions, bookingInProgress: true}}

        case BOOK_COURT_FAILED:
            return {
                ...state,
                selectedTimesCollisions: action.collisionHashArray,
                globalCollisions: action.globalCollisions,
                actions: {...state.actions, bookingInProgress: false}
            };
        case CREATE_SEQUENCE_FAILED:
            return {
                ...state,
                actions: {...state.actions, bookingInProgress: false},
                sequenceCollisions: action.sequenceCollisions
            };
        case GET_SEQUENCES_LOADED: {
            return {
                ...state,
                sequences: action.sequences,
                actions: {...state.actions, sequencesLoading: false}
            };
        }
        case SCHEDULE_LOADED:
            return Object.assign({}, state, {
                courts: action.payload.courts ? action.payload.courts : {},
                loading: false
            });
        case SCHEDULE_LOADING:
            return Object.assign({}, state, {
                loading: true,
                bookDay: action.bookDay,
                courts: action.bookDay === state.bookDay ? state.courts : {}
            });

        case GET_REGISTERED_USERS_LOADING:
            return {
                ...state,
                registeredUsers: [],
                actions: {...state.actions, getRegisteredUsersLoading: true}
            }
        case GET_REGISTERED_USERS_LOADED:
            return {
                ...state,
                registeredUsers: action.users,
                actions: {...state.actions, getRegisteredUsersLoading: false}
            }
        case USER_LOGGED_IN:
            return {
                ...state,
                login: {
                    ...state.login,
                    username: action.user.email,
                    roles: action.user.roles,
                    userLoggedIn: true,
                    loginError: false,
                    showDialog: false
                }
            };
        case USER_LOGIN_FAILED:
            return {
                ...state,
                login: {
                    ...state.login,
                    loginError: true,
                    showDialog: true,
                    username: "",
                    errorMessage: action.errorMessage
                }
            }
        case USER_LOGOUT:
            return {
                ...state,
                login: {
                    username: "",
                    roles: [],
                    loginError: false,
                    userLoggedIn: false
                }
            }
        case SHOW_LOGIN_DIALOG:
            return {...state, login: {...state.login, loginError: false, showDialog: true}}
        case HIDE_LOGIN_DIALOG:
            return {...state, login: {...state.login, showDialog: false}}

        case SELECTED_TIMES_UPDATED:
            let result = {...state};
            result.booking = {...state.booking};
            result.globalCollisions = [];

            let dayUpdated = false;

            if (action.selectedIds) {
                result.booking.selectedTimes = state.booking.selectedTimes.map(selectedDay => {
                    if (selectedDay.day === action.bookDay) {
                        dayUpdated = true;
                        return createNewDay(selectedDay, action);
                    } else {
                        return selectedDay;
                    }
                });

                if (!dayUpdated) {
                    result.booking.selectedTimes.push(createNewDay({day: action.bookDay, courts: []}, action));
                }
            }

            return result;
        case RESET_SELECTED_TIMES:
            return {
                ...state,
                booking: {longTermPeriod: LongTermPeriod.weekly, selectedTimes: []},
                sequenceCollisions: [],
                actions: {...state.actions, bookingInProgress: false}
            };

        case ACTIVATION_START:
            return {...state, activationStatus: ActivationStatus.STARTED};
        case ACTIVATION_FINISHED:
            return {...state, activationStatus: action.activationStatus};

        case SHOW_REMOVE_BOOKING_DIALOG:
            return {
                ...state,
                removeBookingDialog: {
                    show: true,
                    username: action.username,
                    index: action.index,
                    courtCode: action.courtCode,
                    bookDay: action.bookDay
                }
            };

        case HIDE_REMOVE_BOOKING_DIALOG:
            return {...state, removeBookingDialog: {...state.removeBookingDialog, show: false}};
        case REMOVE_BOOKING_FINISHED:
            return {
                ...state,
                removeBookingDialog: {...state.removeBookingDialog, show: false},
                sequenceCollisions: state.sequenceCollisions.filter(
                    sq => (sq.indexFrom !== action.indexFrom || sq.date !== action.bookDay))
            };

        case HANDLE_401_STATUS:
            return {
                ...state,
                login: {
                    username: "",
                    roles: [],
                    loginError: false,
                    userLoggedIn: false,
                    showDialog: true
                }
            }
        default:
            return state;
    }
};

function createNewDay(currentDay, action) {
    let courts = currentDay.courts.filter(court => court.code !== action.courtCode);

    let result = {
        ...currentDay
    };

    if (action.selectedIds.length === 0) {
        result.courts = courts;
    } else {
        result.courts = courts.concat({code: action.courtCode, selectedIds: action.selectedIds});
    }

    return result;
}


export default rootReducer;