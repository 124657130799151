export function toLocalDate(date) {
    if (!date) {
        return null;
    }

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }


    return `${year}-${month}-${day}`;
}

export function toHumanDate(dateStr){
    let date = parseDate(dateStr);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    return `${day}.${month}.${year}`;
}

export function parseDate(dateStr) {
    return new Date(dateStr);
}

export function indexToTimeMin(index) {
    let hour = 7 + Math.floor(index / 2);
    let min = index % 2 === 0 ? ":00" : ":30";
    return hour + "" + min;
}

export function indexToTimeMax(index) {
    return indexToTimeMin(index + 1)
}

export function timeToIndex(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    let index = (hours - 7) * 2;
    if (minutes > 30) {
        index++;
    }

    return index;
}

export function isDateEqual(date1, date2) {
    return date1.getFullYear() === date2.getFullYear()
        && date1.getMonth() === date2.getMonth()
        && date1.getDate() === date2.getDate();

}

export function addMonths(date, numOfMonths){
    date.setMonth(date.getMonth() + numOfMonths);
    return date;
}