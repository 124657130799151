import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {indexToTimeMax, indexToTimeMin} from "../../utils/dateutils";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SequenceCollision extends Component {


    constructor(props, context) {
        super(props, context);
        this.handleRemove = this.handleRemove.bind(this);
    }

    handleRemove(event){
        this.props.handleRemove(this.props.userName,this.props.indexMin, this.props.collisionDay);
    }


    render() {
        const time = this.props.collisionDay + " " + indexToTimeMin(this.props.indexMin) + " - " + indexToTimeMax(this.props.indexMax);
        const checkId = "skipSequenceCheck_" + this.props.bookingId;
        const textColor = this.props.skipSequence ? "text-green" : "text-red";
        return (
            <div className="tile d-flex flex-row p-2 mb-2 bd-highlight border-bottom">
                <div className={"d-flex flex-column flex-grow-1 " + textColor}>
                    <div>
                        <span
                            className="font-weight-bold">{time}</span></div>
                    <div><span
                        className="text-black-50">{this.props.userName}</span>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input className="custom-control-input" name={checkId} id={checkId}
                               type="checkbox" checked={this.props.skipSequence}
                               onChange={() => this.props.skipSequenceHandle(this.props.bookingId ,!this.props.skipSequence)}/>

                        <label className="custom-control-label" htmlFor={checkId}>Ponechat rezervaci</label>
                    </div>
                </div>
                <div>
                    <Button onClick={this.handleRemove} className="btn-light bg-white"><FontAwesomeIcon
                        icon="trash"/></Button>
                </div>
            </div>
        );
    }
}

SequenceCollision.propTypes = {
    bookingId: PropTypes.number,
    collisionDay: PropTypes.string,
    userName: PropTypes.string,
    userId: PropTypes.number,
    indexMin: PropTypes.number,
    indexMax: PropTypes.number,
    skipSequence: PropTypes.bool,
    skipSequenceHandle: PropTypes.func,
    handleRemove: PropTypes.func
};

export default SequenceCollision;