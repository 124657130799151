import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Page from "../Page";
import {registerUser} from "../../actions";
import {inDevelopment} from "../../index";
import './updateregistrationpage.scss';
import RegistrationForm from "../../components/form/RegistrationForm";


class UpdateRegistrationPage extends PureComponent {


    constructor(props, context) {
        super(props, context);
    }

    validateForm(values) {
        console.log(values);
        let errors = {};
        const {firstName, surname, email, phone, personalInfoConsent} = values;

        if (!firstName || firstName.length < 2) {
            errors.firstName = "Jméno musí mít více než 2 znaky";
        }

        if (!surname || surname.length < 2) {
            errors.surname = "Příjmení musí mít více než 2 znaky";
        }

        if (!phone || !phone.match(/^[0-9\\-\\+]{9}$/)) {
            errors.phone = "Nesprávný tvar telefonního čísla";
        }

        if (!personalInfoConsent) {
            errors.personalInfoConsent = "Registraci nelze dokončit bez Vašeho souhlasu";
        }

        console.log("Errors", errors)
        return errors;
    }

    visibleFields = {
        firstName: true, surname: true, phone: true, personalInfoConsent: true
    };

    render() {
        const initValue = inDevelopment() ? {
            firstName: "Tomas",
            surname: "Kubovy",
            phone: "604143329",
            personalInfoConsent: false
        } : {};


        return (
            <Page loginButton className="update-registration-page">
                <div className="container w300">
                    <div className="row justify-content-center mt-4">
                        <h2>Dokončení registrace</h2>
                    </div>


                    <div className="row justify-content-md-center mt-4">
                        <RegistrationForm initValue={initValue} visibleFields={this.visibleFields} validateForm={this.validateForm} onSubmit={(values, actions) => {
                            const {setSubmitting, setErrors} = actions;
                            this.props.registerUser(values, {
                                setSubmitting,
                                setErrors
                            }, () => this.setState({waitForActivation: true}));
                        }}/>
                    </div>

                </div>
            </Page>
        );
    }
}

function mapStateToProps(state) {
    return {
        login: state.login
    };
}


export default connect(
    mapStateToProps, {registerUser}
)(UpdateRegistrationPage);