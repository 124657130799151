import React, {Fragment, PureComponent} from "react";
import {admin} from "../../utils/rights";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';
import {bookingType} from "../../utils/enums";
import {Link} from "react-router-dom";

export class TimePickerRow extends PureComponent {

    render() {
        const isAdmin = admin(this.props.login);
        const adminOrOwner = () => isAdmin || this.props.fullChunkUser.email === this.props.login.username;

        let showDescription = false;
        let showTraining = false;
        let showUserName = false;
        let showTrash = false;

        if (this.props.chunkTop) {
            if (!this.props.longTerm || isAdmin) {
                showDescription = this.props.description ? true : false;
            }

            showTraining = !showDescription && this.props.bookingType === bookingType.training;

            if (adminOrOwner()) {
                showUserName = !showDescription && this.props.bookingType !== bookingType.training
                showTrash = isAdmin || (this.props.fullChunkUser && this.props.index > this.props.deletableIndex);
            }
        }


        const userNameElement = () => isAdmin ?
            <Link to={"/users/" + this.props.fullChunkUser.id}>
                {this.props.fullChunkUser.firstName + ' ' + this.props.fullChunkUser.surname}
            </Link>
            : <Fragment>{this.props.fullChunkUser.firstName + ' ' + this.props.fullChunkUser.surname}</Fragment>;


        return <tr className="time-picker-row">
            <td className={this.props.classNames}
                onClick={() => this.props.onClick(this.props.index)}
            >
                <div className="flex-parent">
                    <div className="short-and-fixed">{this.props.time}</div>


                    {showTraining &&
                    <div className="long-and-truncated ml-3">Trénink</div>
                    }

                    {showDescription &&
                    <div className="long-and-truncated ml-3">{this.props.description}</div>
                    }


                    {showUserName &&
                        <Fragment>
                            {this.props.fullChunkUser &&
                            <div className="long-and-truncated ml-3">
                                {userNameElement()}
                            </div>
                            }
                        </Fragment>
                    }

                    {showTrash &&
                    <div className="short-and-fixed">
                        <FontAwesomeIcon
                            onClick={() => this.props.onRemoveBooking(this.props.fullChunkUser, this.props.index)}
                            className="clickable light"
                            icon="trash"/>
                    </div>
                    }

                </div>
            </td>
        </tr>;
    }
}

TimePickerRow.propTypes = {
    index: PropTypes.number,
    classNames: PropTypes.string,
    onClick: PropTypes.func,
    time: PropTypes.any,
    chunkTop: PropTypes.bool,
    fullChunkUser: PropTypes.object,
    bookingType: PropTypes.string,
    expired: PropTypes.bool,
    deletableIndex: PropTypes.number,
    login: PropTypes.object,
    onRemoveBooking: PropTypes.func,
    description: PropTypes.string,
    longTerm: PropTypes.bool
};