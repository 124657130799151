export function translateCourtCode(code) {
    switch (code) {
        case "KURT_1":
            return "Kurt 1";
        case "KURT_2":
            return "Kurt 2";
        case "KURT_3":
            return "Kurt 3";

            throw new Error("Unknown court code translation " + code);
    }
}
