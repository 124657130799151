import React, {Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';
import {Formik} from "formik";
import FormikInput from "../../components/input/FormikInput";
import Page from "../Page";
import {registerUser} from "../../actions";
import {Link} from "react-router-dom";
import {inDevelopment} from "../../index";
import './registrationpage.scss';
import GoogleButton from "react-google-button";
import RegistrationForm from "../../components/form/RegistrationForm";


class RegistrationPage extends PureComponent {


    constructor(props, context) {
        super(props, context);
        this.state = {waitForActivation: false};
    }

    validateForm(values) {
        let errors = {};
        const {firstName, surname, password1, password2, email, phone, personalInfoConsent} = values;

        if (!firstName || firstName.length < 2) {
            errors.firstName = "Jméno musí mít více než 2 znaky";
        }

        if (!surname || surname.length < 2) {
            errors.surname = "Příjmení musí mít více než 2 znaky";
        }

        if (!phone || !phone.match(/^[0-9\\-\\+]{9}$/)) {
            errors.phone = "Nesprávný tvar telefonního čísla";
        }

        if (!email || !email.match(/^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/)) {
            errors.email = "Nesprávný tvar e-mailu";
        }

        if (!password1 || password1.length < 8) {
            errors.password1 = "Heslo musí mít alespoň 8 znaků";
        }

        if (!password2 || password2 !== password1) {
            errors.password2 = "Zadaná hesla se neschodují";
        }

        if (!personalInfoConsent) {
            errors.personalInfoConsent = "Registraci nelze dokončit bez Vašeho souhlasu";
        }

        return errors;
    }

    visibleFields = {
        firstName: true, surname: true, email: true, phone: true, password: true, personalInfoConsent: true
    };

    render() {
        const initValue = inDevelopment() ? {
            // firstName: "Tomas",
            // surname: "Kubovy",
            // email: "a@b.cz",
            // phone: "604143329",
            // password1: "Heslo123",
            // password2: "Heslo123",
            // personalInfoConsent: true
        } : {};

        return (
            <Page loginButton className="registration-page">
                <div className="container w300">
                    <div className="row justify-content-center mt-4">
                        <h2>Registrace</h2>
                    </div>

                    {this.state.waitForActivation &&

                    <div className="row justify-content-md-center mt-4 text-success bold h4">
                        <div className="text-center">
                            Registrace proběhla úspěšně. Pro její dokončení a možnost vytváření rezervací je nutné účet
                            aktivovat pomocí odkazu, který Vám byl zaslán na zvolenou emailovou adresu.
                        </div>
                        <div className="text-center mt-3">
                            Postupujte dle pokynů v emailu. Děkujeme
                        </div>

                        <div>
                            <Link className="btn btn-primary mt-4" to="/">
                                Pokračovat na rezervace
                            </Link>
                        </div>
                    </div>
                    }

                    {/*TODO kubovy dodělat přesměrování podle referer header, + přesměrovat na formulář pro zadání telefonního čísla*/}
                    {!this.state.waitForActivation &&
                    <Fragment>
                        <div className="row">
                            <small className="text-center">Pro vytváření rezervací a jeji správu je nutné vytvořit
                                osobní registraci vyplněním registračního formuláře.
                            </small>
                            {/*TODO kubovy GOOGLE OAUTH2*/}
                            {/*<small className="text-center">Pro vytváření rezervací a jeji správu je nutné vytvořit*/}
                            {/*    osobní registraci. Využijte registraci převzetím údajů ze sociálního účtu nebo vyplňte registrační formulář.*/}
                            {/*</small>*/}
                            {/*<a className="ml-auto mr-auto d-inline-block w-auto" href="http://localhost:8080/oauth2/authorization/google">*/}
                            {/*    <GoogleButton className="mt-3" type={"light"} label="Google účet"/>*/}
                            {/*</a>*/}
                        </div>
                        <hr/>

                        <div className="row justify-content-md-center mt-4">
                            <RegistrationForm initValue={initValue} validateForm={this.validateForm} visibleFields={this.visibleFields} onSubmit={(values, actions) => {
                                const {setSubmitting, setErrors} = actions;
                                this.props.registerUser(values, {
                                    setSubmitting,
                                    setErrors
                                }, () => this.setState({waitForActivation: true}));
                            }}/>
                        </div>

                    </Fragment>}
                </div>
            </Page>
        );
    }
}

function mapStateToProps(state) {
    return {
        login: state.login
    };
}


export default connect(
    mapStateToProps, {registerUser}
)(RegistrationPage);