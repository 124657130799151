export const ActivationStatus = {
    STARTED: "STARTED",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED"
}

export const bookingType = {
    simple: "SIMPLE",
    training: "TRAINING"
};

export const LongTermPeriod = {
    weekly: {key: "WEEKLY", title: "Každý týden"},
    everySecondWeek: {key: "EVERY_SECOND_WEEK", title: "Každý druhý týden"}
}