import React, {Fragment, PureComponent} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {library} from '@fortawesome/fontawesome-svg-core'
import {faUser} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
import NavDropdown from "react-bootstrap/esm/NavDropdown";


library.add(faUser)

class LoginInfo extends PureComponent {
    render() {

        let loggedUser = this.props.login.userLoggedIn;

        if (loggedUser) {
            return (
                <Fragment>
                    {/*<div className="navbar-text"><FontAwesomeIcon icon="user"/> {this.props.login.username}</div>*/}

                    <NavDropdown title={<Fragment><FontAwesomeIcon icon="user"/> {this.props.login.username}</Fragment>}
                                 id="nav-dropdown">
                        {this.props.changePasswordButton !== false &&
                        <NavDropdown.Item eventKey="4.1">
                            <Link className="nav-item nav-link active p-0" to="/changePassword">
                                Změnit heslo
                            </Link>
                        </NavDropdown.Item>
                        }
                    </NavDropdown>
                    <button onClick={this.props.handleLogout}
                            className="nav-item nav-link active btn btn-link">Odhlásit
                    </button>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    {this.props.loginButton &&
                    <button onClick={this.props.handleLogin}
                            className="nav-item nav-link active btn btn-link">Přihlásit
                    </button>
                    }

                    {this.props.registrationButton &&
                    <Link className="nav-item nav-link active btn btn-link" to="/registration">
                        Registrovat
                    </Link>
                    }
                </Fragment>
            );
        }

    }
}

export default LoginInfo;