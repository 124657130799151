import React, {PureComponent} from 'react';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {parseDate} from "../../utils/dateutils";
import cs from 'date-fns/locale/cs';
import './calendar.scss';


registerLocale('cs', cs)

class Calendar extends PureComponent {


    render() {
        const {selected, handleSelect, minDate, maxDate, ...rest} = this.props;
        return (
            <div className="calendar-parent" {...rest}>
                <DatePicker className="mr-2"
                            locale="cs"
                            isClearable={false}
                            ref={r => this.calendar = r}
                            selected={parseDate(selected)}
                            onChange={handleSelect}
                            minDate={minDate}
                            maxDate={maxDate}
                            dateFormat="dd.MM.yyyy" withPortal/>
                <Button variant="outline-secondary" onClick={() => this.calendar.setOpen(true)}> <FontAwesomeIcon icon="calendar"/></Button>
            </div>
        )
    }
}

export default Calendar;