import React, {Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';
import {bookCourt, bookSequence, selectedTimeUpdated, showLoginDialog, showRemoveBookingDialog} from "../../actions";
import {createBookChunks, hashBooking} from "../../utils/bookutils";
import BookedCourt from "../bookedcourt/BookedCourt";
import './bookedlist.scss'
import AccessControl from "../accesscontrol/AccessControl";
import {admin} from "../../utils/rights";
import {bookingType, LongTermPeriod} from "../../utils/enums";
import ListGroup from "react-bootstrap/esm/ListGroup";
import Form from "react-bootstrap/esm/Form";
import Col from "react-bootstrap/esm/Col";
import Calendar from "../calendar/Calendar";
import {addMonths, parseDate, toHumanDate, toLocalDate} from "../../utils/dateutils";
import Spinner from "react-bootstrap/Spinner";
import SequenceCollision from "./SequenceCollision";


class BookedList extends PureComponent {

    constructor(props, context) {
        super(props, context);

        this.changeBooking = this.changeBooking.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderBookingBtns = this.renderBookingBtns.bind(this);
        this.skipSequenceHandle = this.skipSequenceHandle.bind(this);
        this.handleRemoveSequenceCollision = this.handleRemoveSequenceCollision.bind(this);
        this.state = {skipBookingIds: []}
    }


    componentDidMount() {

        let longTermLastDay = addMonths(new Date(), 3);

        this.setState({
            bookingType: "SIMPLE",
            longTerm: false,
            longTermPeriod: LongTermPeriod.weekly.key,
            longTermLastDay: longTermLastDay,
            description: null,
            skipBookingIds: []
        });
    }

    static getDerivedStateFromProps(props, state) {
        const bookingList = createBookChunks(props.booking.selectedTimes);

        if (bookingList.length > 1) {
            return {longTerm: false}
        }
        return null;
    }




    removeChunk(bookedChunk) {
        const {minId, maxId, courtCode, day} = bookedChunk;
        let dayStr = day;

        let toRemove = Array.from(Array(maxId - minId + 1), (a, index) => index + minId);

        let selectedIds = this.props.booking.selectedTimes.filter((d) => d.day === dayStr)[0]
            .courts.filter(c => c.code === courtCode)[0]
            .selectedIds.filter(s => !toRemove.includes(s));

        this.props.selectedTimeUpdated({
            code: courtCode,
            selectedIds: selectedIds
        }, day);
    }

    isCollision(bookedCourt) {

        for (let i = bookedCourt.minId; i <= bookedCourt.maxId; i++) {
            let hash = hashBooking(bookedCourt.courtCode, bookedCourt.day, i);
            if (this.props.selectedTimesCollisions.includes(hash)) {
                return true;
            }
        }

        return false;
    }

    changeBooking(data) {
        this.setState({
            ...this.state,
            ...data
        })
    }

    handleSubmit() {
        if (this.state.longTerm) {
            const noLongTerm = ({longTerm, ...rest}) => rest;
            this.props.bookSequence({
                ...noLongTerm(this.state),
                longTermLastDay: toLocalDate(this.state.longTermLastDay),
                skipCollisionIds: this.state.skipBookingIds
            });
        } else {
            this.props.bookCourt({bookingType: this.state.bookingType, description: this.state.description});
        }
    }

    renderBookingBtns() {
        return (
            <Fragment>
                {!this.props.login.userLoggedIn &&
                <div className="text-dark small mb-2 mt-3">
                    Pro vytvoření rezervace musíte být přihlášen
                </div>
                }
                <div className="w-100 d-flex justify-content-end">


                    <div className="btn-group mt-3">

                        {!this.props.login.userLoggedIn &&

                        <button type="button" className="btn mr-1"
                                disabled={this.props.login.userLoggedIn}
                                onClick={this.props.showLoginDialog}>Přihlásit
                        </button>
                        }
                        <button type="button" className="btn btn-primary"
                                disabled={!this.props.login.userLoggedIn}
                                onClick={this.handleSubmit}>
                            {this.props.bookingInProgress &&
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            }
                            Rezervovat
                        </button>
                    </div>


                </div>
            </Fragment>
        )
    }

    skipSequenceHandle(bookingId, skipFlag) {
        if (skipFlag) {
            this.setState({
                ...this.state,
                skipBookingIds: [...this.state.skipBookingIds, bookingId]
            });
        } else {
            this.setState({
                ...this.state,
                skipBookingIds: this.state.skipBookingIds.filter(id => id !== bookingId)
            })
        }
    }

    handleRemoveSequenceCollision(username, indexMin, bookDay) {
        const courtCode = this.props.booking.selectedTimes[0].courts[0].code;
        this.props.showRemoveBookingDialog(username, indexMin, bookDay, courtCode);
    }

    render() {
        const bookingList = createBookChunks(this.props.booking.selectedTimes);
        const disableLongTerm = bookingList.length > 1;

        const daySet = new Set();
        bookingList.forEach(booking => daySet.add(booking.day));

        if (daySet.size == 0) {
            return (<div></div>);
        }

        let bookingCollision = false;

        return (
            <div className="bookedList w-100 d-flex flex-column flex-grow-1 bd-highlight">
                {
                    Array.from(daySet)
                        .map((day, index) =>
                            <div className="flex-row w-100" key={index}>
                                <div>{toHumanDate(day)}</div>
                                {
                                    bookingList.filter(booking => booking.day === day)
                                        .map(booking => {
                                            let collision = this.isCollision(booking);
                                            bookingCollision = bookingCollision || collision;
                                            return (
                                                <BookedCourt key={booking.day + booking.courtCode + booking.minId}
                                                             bookDay={booking.day} timeMin={booking.minId}
                                                             timeMax={booking.maxId} courtName={booking.courtCode}
                                                             isCollision={collision}
                                                             handleRemove={() => this.removeChunk(booking)}/>
                                            )
                                        })
                                }
                            </div>
                        )

                }

                <AccessControl rightFn={admin}>
                    <div className="mt-3 p-2">
                        <Form.Group>
                            <Form.Label as="legend" column className="pl-0 font-weight-bold">
                                Typ rezervace
                            </Form.Label>
                            <ListGroup as="ul" className="p-2">
                                <ListGroup.Item className="p-1" as="li" action
                                                onClick={() => this.changeBooking({bookingType: bookingType.simple})}
                                                active={this.state.bookingType === 'SIMPLE'}>Rezervace</ListGroup.Item>
                                <ListGroup.Item className="p-1" as="li" action
                                                onClick={() => this.changeBooking({bookingType: bookingType.training})}
                                                active={this.state.bookingType === 'TRAINING'}>Trénink</ListGroup.Item>
                            </ListGroup>
                            <Form.Group>
                                <Form.Label as="legend" column className="pl-0 font-weight-bold">
                                    Popis
                                </Form.Label>
                                <Form.Control as="textarea" rows="2" value={this.state.description}
                                              onChange={(e) => {
                                                  this.changeBooking({description: e.target.value})
                                              }}
                                />
                            </Form.Group>
                        </Form.Group>

                        <AccessControl rightFn={admin}>
                            <Fragment>
                                {disableLongTerm &&
                                <Form.Text className="text-muted">
                                    Dlouhodobá rezervace lze zadat pouze pro jeden rezervovaný úsek.
                                </Form.Text>
                                }
                                <Form.Group>
                                    <Form.Check
                                        custom
                                        label="Dlouhodobá rezervace"
                                        name="ltpFlag"
                                        id="ltpFlag"
                                        disabled={disableLongTerm}
                                        checked={!!this.state.longTerm}
                                        onChange={() => this.changeBooking({longTerm: !this.state.longTerm})}
                                    />
                                </Form.Group>

                            </Fragment>
                        </AccessControl>

                        {this.state.longTerm &&
                        <Fragment>
                            <fieldset>
                                <Form.Group>
                                    <Form.Label as="legend" column className="pl-0 font-weight-bold">
                                        Frekvence
                                    </Form.Label>
                                    <Col>
                                        <Form.Check
                                            custom
                                            type="radio"
                                            label={LongTermPeriod.weekly.title}
                                            name="ltpWeekly"
                                            id="ltpWeekly"
                                            checked={this.state.longTermPeriod === LongTermPeriod.weekly.key}
                                            onChange={() => this.changeBooking({longTermPeriod: LongTermPeriod.weekly.key})}
                                        />
                                        <Form.Check
                                            custom
                                            type="radio"
                                            label={LongTermPeriod.everySecondWeek.title}
                                            name="ltpEverySecondWeek"
                                            id="ltpEverySecondWeek"
                                            checked={this.state.longTermPeriod === LongTermPeriod.everySecondWeek.key}
                                            onChange={() => this.changeBooking({longTermPeriod: LongTermPeriod.everySecondWeek.key})}
                                        />
                                    </Col>
                                </Form.Group>
                            </fieldset>

                            <Form.Group>
                                <Form.Label as="legend" column className="pl-0 font-weight-bold">
                                    Konec řady
                                </Form.Label>

                                <Calendar selected={this.state.longTermLastDay} className="calendar-parent pl-3"
                                          handleSelect={(date) => this.changeBooking({longTermLastDay: date})}
                                          minDate={new Date()}
                                          maxDate={addMonths(parseDate(bookingList[0].day), 6)}
                                />
                            </Form.Group>
                        </Fragment>
                        }

                    </div>

                    {this.props.sequenceCollisions.map((collision) =>
                        <SequenceCollision
                            key={"col_" + collision.bookingId}
                            bookingId={collision.bookingId}
                            collisionDay={collision.date}
                            userName={collision.userName}
                            userId={collision.userId}
                            indexMin={collision.indexFrom}
                            indexMax={collision.indexTo}
                            skipSequence={this.state.skipBookingIds.includes(collision.bookingId)}
                            skipSequenceHandle={this.skipSequenceHandle}
                            handleRemove={this.handleRemoveSequenceCollision}
                        />
                    )}


                </AccessControl>

                {bookingCollision &&
                <div className="text-red mt-2 p-2 warning" >
                    Některé vybrané časy již nelze zarezervovat.
                </div>
                }

                {this.props.globalCollisions.length > 0 &&
                <div className="text-red mt-2 border-bottom">
                    {
                        this.props.globalCollisions.map(collision => {
                            if (collision.collisionType === "EXCEED_MAXIMAL_LIMIT") {
                                return (<div className="mt-2 p-2 warning" >
                                    V jeden den ({toHumanDate(collision.date)}) můžete zarezervovat pouze 2 hodiny. V případě delšího časového úseku kontaktuje prosím správce kurtů.
                                </div>);
                            }
                        })
                    }
                </div>
                }

                {this.renderBookingBtns()}

            </div>
        )
    }
}

function mapStateToProps(state) {

    return {
        bookingInProgress: state.actions.bookingInProgress,
        bookDay: state.bookDay,
        booking: state.booking,
        selectedTimesCollisions: state.selectedTimesCollisions,
        globalCollisions: state.globalCollisions,
        login: state.login,
        sequenceCollisions: state.sequenceCollisions
    };
}

export default connect(
    mapStateToProps, {
        selectedTimeUpdated,
        bookCourt,
        bookSequence,
        showLoginDialog,
        showRemoveBookingDialog
    }
)(BookedList);