import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import {render} from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store from "./redux/index";
import {HashRouter} from 'react-router-dom';

import history from './history';
import HttpsApp from "./HttpsApp";

render(
    <Provider store={store}>
        <HashRouter history={history}>
            <HttpsApp/>
        </HashRouter>
    </Provider>,
    document.getElementById('root')
);

export function inDevelopment() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
