import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';


class Input extends PureComponent {


    constructor(props, context) {
        super(props, context);

        this.state = {value: ''}
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({value: this.props.value || ""});
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            value: nextProps.value,
        };
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    render() {
        return (
            <div>
                <input {...this.props} name={this.props.name} placeholder={this.props.placeholder} type={this.props.type}
                       value={this.state.value} onChange={this.handleChange}/>
            </div>
        );
    }
}

Input.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string
}

export default Input;