import React, {PureComponent} from 'react';
import Navigation from "../components/navigation/Navigation";
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";


class Page extends PureComponent {
    render() {
        const access = this.props.rightFn ? this.props.rightFn(this.props.login) : true;

        return (
            <div className={this.props.className}>
                <Navigation {...this.props}/>
                {access && this.props.children}
                {!access && <div>
                    Nemáte oprávnění na tuto stránku
                </div>}
            </div>
        );
    }
}

Page.propTypes = {
    loginButton: PropTypes.bool,
    registrationButton: PropTypes.bool,
    changePasswordButton: PropTypes.bool,
    rightFn: PropTypes.func
};

function mapStateToProps(state) {

    return {
        login: state.login,
    }
}

export default connect(mapStateToProps)(Page);