import React, {PureComponent} from 'react';
import './schedulepage.scss'
import TimePicker from "../../components/timepicker/TimePicker";
import {connect} from "react-redux";
import {getCourtSchedule, showLoginDialog} from "../../actions/index";
import "react-datepicker/dist/react-datepicker.css";
import {ReactComponent as Kurt1Img} from "./../../img/kurt_1.svg"
import {ReactComponent as Kurt2Img} from "./../../img/kurt_2.svg"
import {ReactComponent as Kurt3Img} from "./../../img/kurt_3.svg"
import DateSelectionRow from "../../components/dateselection/DateSelectionRow";
import BookedList from "../../components/bookedlist/BookedList";
import {evaluateExpiredIndex, isBookedChunk} from "../../utils/bookutils";
import Page from "../Page";
import {toLocalDate} from "../../utils/dateutils";
import {MOBILE_WINDOW_WIDTH_MAX} from "../../config/config";
import {translateCourtCode} from "../../utils/courtUtils";
import {showRemoveBookingDialog, userLoginFailed} from "../../actions";
import RemoveBookingDialog from "../../components/removebookingdialog/RemoveBookingDialog";
import queryString from 'query-string'
import Tabs from "react-bootstrap/esm/Tabs";
import Tab from "react-bootstrap/esm/Tab";
import LoadingSpinner from "../../components/spinner/LoadingSpinner";
import DisabledOverlay from "../../components/overlay/DisabledOverlay";

class SchedulePage extends PureComponent {

    constructor(props, context) {
        super(props, context);
        this.handleSelectDate = this.handleSelectDate.bind(this);
        this.state = {windowWidth: window.innerWidth};
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        const date = values.date ? values.date : toLocalDate(new Date());
        this.props.getCourtSchedule(date);

        window.addEventListener('resize', () => {
                this.setState({windowWidth: window.innerWidth});
            }
        );

        const loc = this.props.location;
        if (loc.pathname === "/loginError") {
            window.location.hash = "#/";
            const error = values.error ? values.error : "";
            console.log("error: ", error);
            this.props.userLoginFailed(error);
        }
    }


    handleSelectDate(date) {
        if (date) {
            this.props.getCourtSchedule(date);
        }
    }

    isMobile() {
        return this.state.windowWidth < MOBILE_WINDOW_WIDTH_MAX;
    }


    render() {
        let expiredIndex = evaluateExpiredIndex(this.props.bookDay);

        let courtArray = [
            {
                code: "KURT_1", name: translateCourtCode("KURT_1"),
                img: <Kurt1Img className="kurt-logo"/>,
                description: "Spodní kurt"
            },
            {
                code: "KURT_2", name: translateCourtCode("KURT_2"),
                img: <Kurt2Img className="kurt-logo"/>,
                description: "Prostřední kurt"
            },
            {
                code: "KURT_3", name: translateCourtCode("KURT_3"),
                img: <Kurt3Img className="kurt-logo"/>,
                description: "U fotbalového hřiště"
            }];

        return (
            <Page loginButton registrationButton>
                <RemoveBookingDialog/>

                <div className="schedule-page container mt-2">
                    <LoadingSpinner loading={this.props.loading}/>
                    <div className="row">
                        <div className="d-flex col-xl-9 col-12 p-2 flex-wrap shadow bg-white rounded">
                            <DateSelectionRow bookDay={this.props.bookDay}
                                              handleSelectDate={this.handleSelectDate}/>

                        </div>
                    </div>

                    {!this.isMobile() &&
                    <div className="row">
                        {courtArray.map(court => {
                            return (
                                <div key={court.code}
                                     className="col-sm-12 col-md-3 p-3 mb-5 mt-3 shadow bg-white rounded">
                                    <div className="d-flex flex-row">
                                        <div className="flex-grow-1"><h3>{court.name}</h3></div>
                                        <div>
                                            {court.img}
                                        </div>
                                    </div>
                                    <TimePicker expiredIndex={expiredIndex}
                                                courtCode={court.code}
                                                fullIds={this.props.courts[court.code]}
                                                handleBookingDelete={this.props.showRemoveBookingDialog}
                                                handleSelection={(selectedIndex) => {
                                                    this.handleSelection(court.code, selectedIndex)
                                                }}
                                    />
                                </div>
                            )
                        })}
                        <div className="col-3 mt-3 pr-0">
                            {isBookedChunk(this.props.selectedTimes) &&
                            <div className="shadow bg-white rounded p-2 position-relative">
                                <DisabledOverlay display={this.props.bookingInProgress}/>
                                <BookedList/>
                            </div>
                            }
                        </div>
                    </div>
                    }

                    {this.isMobile() &&
                    <div className="row shadow bg-white rounded mt-2">
                        <div className="mt-2 col-12 col-md-auto align-self-center">
                            <Tabs defaultActiveKey="KURT_1" id="uncontrolled-tab-example">
                                {
                                    courtArray.map(court =>
                                        <Tab eventKey={court.code} title={court.name}>
                                            <div className="ml-1 p-1">
                                                {court.img} <span>- {court.description}</span>
                                            </div>
                                            <TimePicker
                                                expiredIndex={expiredIndex}
                                                courtCode={court.code}
                                                fullIds={this.props.courts[court.code]}
                                                handleBookingDelete={this.props.showRemoveBookingDialog}
                                                handleSelection={(selectedIndex) => {
                                                    this.handleSelection(court.code, selectedIndex)
                                                }}
                                            />
                                        </Tab>
                                    )

                                }

                            </Tabs>
                        </div>
                    </div>
                    }


                    {this.isMobile() && isBookedChunk(this.props.selectedTimes) &&
                    <div className="row shadow bg-white rounded mt-2 p-2 position-relative">
                        <DisabledOverlay display={this.props.bookingInProgress}/>
                        <h3>Rezervace</h3>
                        <BookedList/>
                    </div>
                    }


                </div>
            </Page>
        );
    }
}

SchedulePage.propTypes = {};

function mapStateToProps(state) {

    return {
        bookingInProgress: state.actions.bookingInProgress,
        courts: state.courts,
        loading: state.loading,
        bookDay: state.bookDay,
        selectedTimes: state.booking.selectedTimes
    }
}

export default connect(mapStateToProps, {
    getCourtSchedule,
    showLoginDialog,
    userLoginFailed,
    showRemoveBookingDialog
})(SchedulePage);