import React, {PureComponent} from 'react';
import Input from "../Input";
import {inDevelopment} from "../../index";
import Button from "react-bootstrap/Button";


class LoginForm extends PureComponent {


    constructor(props, context) {
        super(props, context);

        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.prefillUser = this.prefillUser.bind(this);
    }

    prefillUser(e, name, pass) {
        e.preventDefault();

        this.setState({name: name, pass: pass});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.handleSubmit(this.form.username.value, this.form.password.value);
    }

    render() {
        let errorMessage;
        if (this.props.loginError) {
            errorMessage = this.props.errorMessage;
        }

        return (
            <form id={this.props.id} method={"POST"} action={"/perform_login"} onSubmit={this.handleSubmit}
                  ref={fm => {
                      this.form = fm
                  }}>

                {inDevelopment() &&
                <div>
                    <button onClick={(e) => this.prefillUser(e, "admin@b.cz", "Heslo123")}>
                        Admin
                    </button>
                    <button onClick={(e) => this.prefillUser(e, "user@tkdd.cz", "Heslo123")}>
                        User
                    </button>
                </div>
                }

                {this.props.loginError &&
                <p className="text-danger small">{errorMessage}</p>
                }
                <div className="form-group mt-2">
                    {/*<div className="mt-3 font-weight-bold">*/}
                    {/*     Přilhášení zaregistrovaným účtem*/}
                    {/*</div>*/}
                    <label htmlFor="usernameInput">Email</label>
                    <Input name={"username"} type="text" className="form-control" id="usernameInput"
                           placeholder="Zadejte email" value={this.state.name}/>
                </div>
                <div className="form-group">
                    <label htmlFor="passwordInput">Heslo</label>
                    <Input name={"password"} type="password" className="form-control" id="passwordInput"
                           placeholder="Zadejte heslo"
                           value={this.state.pass}/>
                </div>
                <div className="modal-footer">
                    <Button variant="secondary" onClick={this.props.handleClose}>
                        Zavřít
                    </Button>
                    <Button form={"loginForm"} type="submit" variant="primary">
                        Přihlásit
                    </Button>
                </div>
            </form>
        );
    }
}


export default LoginForm;